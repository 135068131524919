















import Vue, { PropType } from 'vue'
import { Tracker } from '../../../../shared/types/types'
import Separator from '../shared/Separator.vue'

export default Vue.extend({
  name: 'FulfillmentTrackerInfo',
  components: {
    Separator,
  },
  props: {
    tracker: {
      type: Object as PropType<Tracker>,
      required: true,
    },
  },
})
