


















































































import Vue from 'vue'
import NavigateBack from '../../components/shared/NavigateBack.vue'
import RefundItemCard from '../../components/orders/RefundItemCard.vue'
import LineItemCard from '../../components/orders/LineItemCard.vue'
import RefundSummaryCard from '../../components/orders/RefundSummaryCard.vue'
import { getOrder } from '../../lib/order'
import Loader from '../../components/shared/Loader.vue'
import {
  CurrencyEnum,
  Order,
  VariantTypeEnum,
  FulfillmentStatusEnum,
  Price,
  LineItem,
  Refund,
  RefundLineItemInput,
  Tax,
  CurrencyPair,
} from '../../../../shared/types/types'
import { LineItemExtended } from '../../types'
import {
  groupedFulfillmentLineItems,
  setFulfilledLineItem,
  changeSelectedLineItems,
  filterRefundedLineItems,
} from '../../lib/utils'
import { getCurrencyPair } from '@/lib/currencies'

export default Vue.extend({
  name: 'OrderRefund',
  components: {
    NavigateBack,
    RefundItemCard,
    LineItemCard,
    RefundSummaryCard,
    Loader,
  },
  data() {
    return {
      reasonForRefund: '',
      order: {} as Order,
      currencyPair: null as CurrencyPair | null,
      selected: {
        fulfilled: {
          quantity: 0,
          amount: 0,
          taxes: [] as Tax[],
        },
        unFulfilled: {
          quantity: 0,
          amount: 0,
          taxes: [] as Tax[],
        },
      },
      lineItems: {
        fulfilled: [] as LineItemExtended[],
        unFulfilled: [] as LineItemExtended[],
      },
      receivedOrder: false,
      processRestockLineItems: false,
      VariantTypeEnum,
      FulfillmentStatusEnum,
      selectedLineItems: [] as RefundLineItemInput[],
    }
  },
  computed: {
    orderId(): string {
      return this.$route.params.id
    },
    currency(): CurrencyEnum {
      return this.order.totalPrice?.currency || this.currency
    },
    totalTaxes(): Tax[] {
      const totalTaxes = [] as Tax[]
      this.selected.unFulfilled.taxes
        .concat(this.selected.fulfilled.taxes)
        .forEach((tax) => {
          const currentTaxInd = totalTaxes.findIndex(
            (currentTax) => currentTax.taxId === tax.taxId
          )
          if (currentTaxInd !== -1) {
            ;(totalTaxes[currentTaxInd].taxPrice as Price).amount =
              ((totalTaxes[currentTaxInd].taxPrice as Price).amount || 0) +
              (tax.taxPrice?.amount || 0)
          } else {
            totalTaxes.push(tax)
          }
        })
      return totalTaxes
    },
    totalSelectedQuantity(): number {
      return (
        this.selected.unFulfilled.quantity + this.selected.fulfilled.quantity
      )
    },
    totalSelectedAmount(): Price {
      return {
        amount:
          this.selected.unFulfilled.amount + this.selected.fulfilled.amount,
        currency: this.currency,
      }
    },
    showRefundText(): string {
      return this.order?.netPayment?.amount === 0 ? 'Restock' : 'Refund'
    },
    showRefundLineItems() {
      return (lineItems: LineItemExtended[]) =>
        lineItems.filter(
          (lineItem) =>
            this.order?.netPayment?.amount &&
            (lineItem.taxes?.some((tax) => tax?.taxPrice?.amount) ||
              lineItem.totalPrice?.amount)
        ).length !== 0 && !this.order.canceledAt
    },
  },
  async created() {
    try {
      this.order = (await getOrder(this.orderId)) as Order
      if (
        this.order.totalPrice?.currency !== 'USD' &&
        this.order.totalPrice?.currency
      ) {
        const response = await getCurrencyPair(this.order.totalPrice?.currency)
        if (response?.pair) {
          this.currencyPair = response?.pair
        }
      }
      this.preProcessLineItems()
      this.receivedOrder = true
    } catch (error) {
      console.error('🚀 ~ file: OrderRefund.vue:218 ~ created ~ error', error)
      this.$buefy.toast.open({
        message: 'Order is not available',
        position: 'is-bottom',
        type: 'is-danger',
      })
    }
  },
  methods: {
    goToOrderDetails() {
      this.$router.push(`/orders/${this.orderId}`)
    },
    /**
     * preProcessLineItems to sets lineItems data variable with filtered lineItems from order data
     */
    preProcessLineItems() {
      const filterRefundedLineItemsResponse = filterRefundedLineItems(
        this.order?.lineItems as LineItem[],
        (this.order?.refunds as Refund[]) || []
      )
      this.order.lineItems = filterRefundedLineItemsResponse?.lineItems
      this.order.lineItems = (this.order.lineItems as LineItem[]).map(
        (lineItem) => {
          lineItem.taxes = (lineItem.taxes as Tax[]).map((tax) => {
            ;(tax.taxPrice as Price).amount =
              ((tax.taxPrice as Price).amount || 0) / (lineItem?.quantity || 0)
            return tax
          })
          return lineItem
        }
      )
      this.setUnfulfilled()
      this.setFulfilled(this.order || {})
    },
    /**
     * Sets lineItems.unFulfilled, adds selectedQuantity to lineItems
     */
    setUnfulfilled() {
      const unfulfilledLineItems = groupedFulfillmentLineItems(
        FulfillmentStatusEnum.Unfulfilled,
        undefined,
        this.order?.lineItems || [],
        this.order?.fulfillments
      )
      //   Set selectedQuantity in lineItems
      this.lineItems.unFulfilled = unfulfilledLineItems.length
        ? unfulfilledLineItems[0].lineItems.map((item) => {
            item = { ...item, selectedQuantity: 0 }
            return item
          })
        : []
    },

    /**
     * Sets lineItems.unFulfilled, adds selectedQuantity to lineItems
     */
    setFulfilled(order: Order) {
      // order.fulfillments has lineItems gouped for each fulfillment. Here, we're grouping them to single fulfilledLineitems array
      const fulfilledItem = setFulfilledLineItem(order)
      this.lineItems.fulfilled = fulfilledItem.length
        ? (fulfilledItem as LineItemExtended[]).map((item) => {
            item = { ...item, selectedQuantity: 0 }
            return item
          })
        : []
    },

    /**
     * Changes selected quantity in order object, updates totalQuantitySelected property
     *
     * @param item data transfered by LineItemExtended when user changes quantity of lineItems.
     * @param fulfillmentVariant - FulfillmentStatusEnum
     */
    changeSelectedQuantity(
      item: LineItemExtended,
      fulfillmentVariant: FulfillmentStatusEnum
    ) {
      if (fulfillmentVariant === FulfillmentStatusEnum.Unfulfilled) {
        this.selected.unFulfilled = changeSelectedLineItems(
          item,
          this.lineItems.unFulfilled
        )
      } else if (fulfillmentVariant === FulfillmentStatusEnum.Fulfilled) {
        this.selected.fulfilled = changeSelectedLineItems(
          item,
          this.lineItems.fulfilled
        )
      }
      this.selectedLineItems = [
        ...this.lineItems.unFulfilled,
        ...this.lineItems.fulfilled,
      ]
        .filter((lineItem) => lineItem.selectedQuantity)
        .map((lineItem) => ({
          id: lineItem.lineItemId as string,
          quantity: lineItem.selectedQuantity as number,
        }))
    },
    restockLineItems(processRestockItems: boolean) {
      this.processRestockLineItems = processRestockItems
    },
  },
})
