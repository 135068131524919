
























import Vue, { PropType } from 'vue'
import { Tracker } from '../../../../shared/types/types'
import FulfillmentTrackerInfo from './FulfillmentTrackerInfo.vue'

export default Vue.extend({
  name: 'LineItemCard',
  components: {
    FulfillmentTrackerInfo,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    customIconClass: {
      type: String,
      default: 'bg-green-200',
    },
    subTitle: {
      type: String,
      default: '',
    },
    tracker: {
      type: Object as PropType<Tracker>,
      default: null,
    },
  },
})
