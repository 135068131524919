






































































































































































import Vue, { PropType } from 'vue'
import pluralize from 'pluralize'
import PaymentLineItem from '../../components/orders/PaymentLineItem.vue'
import Separator from '../../components/shared/Separator.vue'
import { formatPrice } from '../../lib/utils'
import {
  CurrencyEnum,
  CurrencyPair,
  Order,
  Price,
  Maybe,
  PriceInput,
  RefundLineItemInput,
  RestockedLineItemInput,
  Tax,
  PaymentStatusEnum,
} from '../../../../shared/types/types'
import { refundOrder, restockLineItems } from '../../lib/order'
import { Prop } from 'vue/types/options'
import { getDecimalPlaces } from '@/lib/currencies'

export default Vue.extend({
  name: 'RefundSummaryCard',
  components: {
    PaymentLineItem,
    Separator,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    currencyPair: {
      type: Object as PropType<Maybe<CurrencyPair>>,
      required: false,
      default: null,
    },
    taxes: {
      type: Array as Prop<Tax[]>,
      required: true,
    },
    selectedItems: {
      type: Number,
      required: true,
    },
    refundSubTotalPrice: {
      type: Object as PropType<Price>,
      required: true,
    },
    reason: {
      type: String,
      required: true,
    },
    selectedLineItems: {
      type: Array as PropType<RefundLineItemInput[]>,
      required: true,
    },
  },
  data() {
    return {
      // TODO: set default values
      manualLineItemsRefundAmount: { ...this.refundSubTotalPrice },
      manualShippingRefundAmount: { ...this.refundSubTotalPrice },
      manualTaxRefundAmount: { ...this.refundSubTotalPrice },
      refundTotalPrice: { ...this.refundSubTotalPrice },
      sendMessageToTheCustomer: true,
      isLoading: false,
    }
  },
  computed: {
    currency(): CurrencyEnum {
      return this.order.totalPrice?.currency as CurrencyEnum
    },
    inputSteps(): number {
      const decimalPlaces = getDecimalPlaces(this.currency)
      return 1 / Math.pow(10, decimalPlaces)
    },
    isOrderInUSD(): boolean {
      return this.currency === CurrencyEnum.Usd
    },
    hasPaymentMethod(): boolean {
      return !!this.order.charge?.paymentMethod
    },
    paymentMethod(): string {
      switch (this.order.charge?.paymentMethod?.type) {
        case 'card':
          return `${this.order.charge?.paymentMethod?.card.network} (.... ${this.order.charge?.paymentMethod?.card.last4})`
        default:
          return ''
      }
    },
    enableRefund(): boolean {
      return this.selectedItems !== 0
    },
    formattedPrice() {
      return (price: Price) => formatPrice(price)
    },
    totalSelectedItemsText(): string {
      return pluralize('Item', this.selectedItems, true)
    },
    decimalPlaces(): number {
      return getDecimalPlaces(this.currency)
    },
    pow(): number {
      return Math.pow(10, this.decimalPlaces)
    },
    isLineItemRefundAmountDifferent(): boolean {
      return !!(
        this.selectedItems &&
        Math.floor(
          (this.manualLineItemsRefundAmount.amount || 0) * this.pow
        ) !== (this.refundItemPrice.amount || 0)
      )
    },
    isShippingRefundAmountDifferent(): boolean {
      return !!(
        this.selectedItems &&
        Math.floor((this.manualShippingRefundAmount.amount || 0) * this.pow) !==
          (this.refundItemPrice.amount || 0)
      )
    },
    isInvalidLineItemsRefundAmount(): boolean {
      if (this.manualLineItemsRefundAmount.amount === 0) return false
      return (
        (this.manualLineItemsRefundAmount.amount || 0) * this.pow >
        (this.lineItemsRefundAvailableAmount.amount || 0)
      )
    },
    isInvalidShippingRefundAmount(): boolean {
      if (this.manualShippingRefundAmount.amount === 0) return false
      return (
        (this.manualShippingRefundAmount.amount || 0) * this.pow >
        (this.shippingRefundAvailableAmount.amount || 0)
      )
    },
    isInvalidTaxRefundAmount(): boolean {
      if (this.manualTaxRefundAmount.amount === 0) return false
      return (
        (this.manualTaxRefundAmount.amount || 0) * this.pow >
        (this.taxRefundAvailableAmount.amount || 0)
      )
    },
    manualLineItemsRefundMessage(): string {
      return this.isInvalidLineItemsRefundAmount
        ? "Can't return more than available"
        : ''
    },
    manualShippingRefundMessage(): string {
      return this.isInvalidShippingRefundAmount
        ? "Can't return more than available"
        : ''
    },
    manualTaxRefundMessage(): string {
      return this.isInvalidTaxRefundAmount
        ? "Can't return more than available"
        : ''
    },
    lineItemInputType(): string {
      return this.isInvalidLineItemsRefundAmount ? 'is-danger' : ''
    },
    shippingInputType(): string {
      return this.isInvalidShippingRefundAmount ? 'is-danger' : ''
    },
    taxInputType(): string {
      return this.isInvalidTaxRefundAmount ? 'is-danger' : ''
    },
    invalidForRefund(): boolean {
      if (this.totalRefundAmount.amount === 0) return true
      return (
        this.isInvalidLineItemsRefundAmount ||
        this.isInvalidTaxRefundAmount ||
        this.isInvalidShippingRefundAmount
      )
    },
    totalRefundAmount(): Price {
      return {
        amount:
          Number(this.manualLineItemsRefundAmount.amount || 0) +
          Number(this.manualTaxRefundAmount.amount || 0) +
          Number(this.manualShippingRefundAmount.amount || 0),
        currency: this.currency,
      }
    },
    refundButtonText(): string {
      const manualAmount = formatPrice({
        amount: Number(
          ((this.totalRefundAmount.amount || 0) * this.pow).toFixed(
            this.decimalPlaces
          )
        ),
        currency: this.currency,
      })
      return `${manualAmount} ${this.currency}`
    },
    convertedRefundButtonText(): string {
      const price = {
        amount: Number(
          ((this.totalRefundAmount.amount || 0) * this.pow).toFixed(
            this.decimalPlaces
          )
        ),
        currency: this.currency,
      }
      const convertedPrice = this.convertedPrice(price)
      return `${formatPrice(convertedPrice)} ${convertedPrice.currency}`
    },
    isOrderFree(): boolean {
      return this.order.netPayment?.amount === 0
    },
    shouldShowRestockButton(): boolean {
      if (!this.invalidForRefund) {
        return false
      }

      if (
        this.order?.lineItemQuantities &&
        this.order?.paymentStatus === PaymentStatusEnum.Refunded
      ) {
        const lineItemQuantities = JSON.parse(this.order?.lineItemQuantities)
        const response = Object.keys(lineItemQuantities).reduce(
          (output, key, _) => {
            const value = lineItemQuantities[key] as {
              availableToRestockQuantity: number
              restockedQuantity: number
            }
            return output || value.availableToRestockQuantity !== 0
          },
          false
        )
        return response
      }
      return false
    },
    refundItemPrice(): Price {
      const refundTotalPriceAmount =
        (this.refundTotalPrice.amount || 0) +
        (this.order.shippingPrice?.amount || 0) +
        (this.order.taxPrice?.amount || 0)
      return {
        amount: refundTotalPriceAmount,
        currency: this.currency,
      }
    },
    lineItemsRefundAvailableAmount(): Price {
      const amount =
        (this.order.finalSubTotalPrice?.amount || 0) -
        (this.order.lineItemsRefund?.amount || 0) -
        (this.order.totalDiscountPrice?.amount || 0)
      return {
        amount: amount > 0 ? amount : 0,
        currency: this.currency,
      }
    },
    shippingRefundAvailableAmount(): Price {
      const amount = this.order.shippingPrice?.amount || 0
      return {
        amount: amount > 0 ? amount : 0,
        currency: this.currency,
      }
    },
    taxRefundAvailableAmount(): Price {
      const amount = this.order.taxPrice?.amount || 0
      return {
        amount: amount > 0 ? amount : 0,
        currency: this.currency,
      }
    },
  },
  watch: {
    refundSubTotalPrice(refundSubTotalPrice) {
      const totalTax = this.taxes
        .map((tax) => tax?.taxPrice?.amount)
        .reduce((prev, tax) => (prev || 0) + (tax || 0), 0)
      this.refundTotalPrice = {
        ...refundSubTotalPrice,
        amount: refundSubTotalPrice.amount + (totalTax || 0),
      }
      // this.manualLineItemsRefundAmount = {
      //   ...this.refundTotalPrice,
      //   amount: (this.refundTotalPrice.amount || 0) / 100,
      // }
    },
    isOrderInUSD(isOrderInUSD) {
      if (!isOrderInUSD) {
        // fetch exchange rate
      }
    },
  },
  methods: {
    convertedPrice(price: Price): Price {
      const decimalPlacesDiff = 2 - getDecimalPlaces(this.currency)
      if (!this.currencyPair) {
        return {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      }
      if (typeof price?.amount !== 'number') {
        throw new Error('Price is not valid')
      }
      const amount = price.amount / this.currencyPair.rate

      const result = {
        amount: amount * Math.pow(10, decimalPlacesDiff),
        currency: CurrencyEnum.Usd,
      }
      return result
    },
    async refundOrder() {
      const refund = {
        params: {
          lineItemsRefundAmount: {
            ...(this.manualLineItemsRefundAmount as PriceInput),
            amount: Math.round(
              Number(
                (Number(this.manualLineItemsRefundAmount.amount) || 0).toFixed(
                  this.decimalPlaces
                )
              ) * this.pow
            ),
          },
          shippingRefundAmount: {
            ...(this.manualShippingRefundAmount as PriceInput),
            amount: Math.round(
              Number(
                (Number(this.manualShippingRefundAmount.amount) || 0).toFixed(
                  this.decimalPlaces
                )
              ) * this.pow
            ),
          },
          taxRefundAmount: {
            ...(this.manualTaxRefundAmount as PriceInput),
            amount: Math.round(
              Number(
                (Number(this.manualTaxRefundAmount.amount) || 0).toFixed(
                  this.decimalPlaces
                )
              ) * this.pow
            ),
          },
          reason: this.reason,
          notifyCustomer: this.sendMessageToTheCustomer,
        },
      }
      if (this.selectedLineItems.length !== 0) {
        Object.assign(refund.params, {
          lineItems: this.selectedLineItems.filter(
            (lineItem) => !!Number(lineItem.quantity)
          ),
        })
      }
      this.isLoading = true
      try {
        await refundOrder(this.orderId, refund)
        this.isLoading = false
        this.$buefy.toast.open({
          message: 'Order has been Refunded!',
          type: 'is-success',
          position: 'is-bottom',
        })
        // Restock Items on successfull refund
        this.$emit('restock-items', true)

        setTimeout(() => {
          // Custom delay to let restock-items process a request
          this.$router.push(`/orders/${this.orderId}`)
        }, 2000)
      } catch (error) {
        this.isLoading = false
        this.$buefy.toast.open({
          message: 'Failed to refund the order. Please try again later.',
          type: 'is-danger',
          position: 'is-bottom',
        })
        // Don't Restock Items on refund failure
        this.$emit('restock-items', false)
      }
    },
    async restockOrderItems() {
      try {
        this.isLoading = true
        // Restock everything what's possible
        const getRestockedLineItems: RestockedLineItemInput[] = []
        const lineItemQuantities = JSON.parse(this.order?.lineItemQuantities)
        Object.keys(lineItemQuantities).forEach((key) => {
          const value = lineItemQuantities[key] as {
            availableToRestockQuantity: number
          }
          getRestockedLineItems.push({
            lineItemId: key,
            quantity: value.availableToRestockQuantity,
          })
        })

        await restockLineItems(this.orderId, getRestockedLineItems)
        this.$buefy.toast.open({
          message: 'Order Items has been Restocked!',
          type: 'is-success',
          position: 'is-bottom',
        })
        this.$router.push(`/orders/${this.orderId}`)
      } catch (error) {
        this.$buefy.toast.open({
          message: 'Failed to restock the Order items. Please try again later.',
          type: 'is-danger',
          position: 'is-bottom',
        })
      } finally {
        this.isLoading = false
      }
    },
  },
})
