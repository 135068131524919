

























































import { LineItemExtended } from '@/types'
import Vue, { PropType } from 'vue'
import {
  CurrencyEnum,
  Price,
  RestockedLineItemInput,
  Order,
  LineItem,
} from '../../../../shared/types/types'
import Separator from '../../components/shared/Separator.vue'
import { formatPrice } from '../../lib/utils'
import { restockLineItems } from '../../lib/order'
import LineItemImage from '../shared/ItemImage.vue'

export default Vue.extend({
  name: 'RefundItemCard',
  components: {
    Separator,
    LineItemImage,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    lineItems: {
      type: Array as PropType<LineItemExtended[]>,
      required: true,
    },
    selectedItems: {
      type: Number,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    processRestockLineItems: {
      type: Boolean,
      default: true,
    },
    fulfillmentType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      shouldRestockLineItems: true,
    }
  },
  computed: {
    formattedPrice() {
      return (price: Price) => {
        return price
          ? formatPrice(price)
          : formatPrice({ amount: 0, currency: CurrencyEnum.Usd })
      }
    },
    enableRestock(): boolean {
      return this.selectedItems === 0
    },
  },
  watch: {
    processRestockLineItems(processRestockLineItems) {
      if (processRestockLineItems && this.shouldRestockLineItems) {
        this.doRestockLineItems()
      }
    },
  },
  created() {
    this.lineItems.forEach((lineItem) => {
      this.calculateLineItemSelectedPrice(lineItem)
    })
  },
  methods: {
    refundLineItemCost(lineItem: LineItem) {
      return (
        this.order?.netPayment?.amount &&
        (lineItem.taxes?.some((tax) => tax?.taxPrice?.amount) ||
          lineItem.totalPrice?.amount)
      )
    },
    async doRestockLineItems(): Promise<void> {
      try {
        await restockLineItems(this.orderId, this.getRestockedLineItems())
        this.$buefy.toast.open({
          message: 'Order has been Restocked!',
          type: 'is-success',
          position: 'is-bottom',
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: 'Failed to restock the line items. Please try again later.',
          type: 'is-danger',
          position: 'is-bottom',
        })
      }
    },
    /**
     * Calculates the item price based on selected quantity.
     *
     * @param lineItem - contains details of item, updated quantity
     */
    calculateLineItemSelectedPrice(lineItem: LineItemExtended) {
      lineItem.selectedPrice = {
        amount:
          (lineItem.selectedQuantity || 0) *
          (lineItem.originalPrice?.amount || 0),
        currency: lineItem.originalPrice?.currency || CurrencyEnum.Usd,
      }
    },
    /**
     * It emits an event which is handled by parent component.
     *
     * @param item - contains details of item, updated quantity
     */
    emitLineItemQuantitySelect(item: LineItemExtended) {
      this.calculateLineItemSelectedPrice(item)
      this.$emit('change-selected-quantity', item, this.fulfillmentType)
    },
    getRestockedLineItems(): RestockedLineItemInput[] {
      return this.lineItems
        .filter((lineItem) => lineItem?.selectedQuantity)
        .map((lineItem) => ({
          lineItemId: lineItem?.lineItemId,
          quantity: lineItem?.selectedQuantity,
        })) as RestockedLineItemInput[]
    },
  },
})
