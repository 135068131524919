
















































import Vue, { PropType } from 'vue'
import { Price } from '../../../../shared/types/types'
import { formatPrice } from '../../lib/utils'

export default Vue.extend({
  name: 'PaymentLineItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    price: {
      type: Object as PropType<Price>,
      default: () => ({ currency: 'USD', amount: 0 }),
    },
    priceAsterisk: {
      type: Boolean,
      default: false,
    },
    isBold: {
      type: Boolean,
      default: false,
    },
    usdPrice: {
      type: Object as PropType<Price>,
      default: () => null,
    },
    isNegativeAmount: {
      type: Boolean,
      default: false,
    },
    metadata: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    priceList: {
      type: Array as PropType<Array<any>>,
      default: () => [],
    },
  },
  computed: {
    amountPrefix(): (isNegative: boolean) => string {
      return (isNegative: boolean) => {
        return isNegative ? '-' : ''
      }
    },
    shouldShowList(): boolean {
      return !!(this.priceList as Array<any>).length
    },
    shouldShowUsdPrice(): boolean {
      return this.price.currency !== 'USD' && !!this.usdPrice
    },
    shouldShowPriceAstrisk(): boolean {
      return this.priceAsterisk && this.shouldShowUsdPrice
    },
  },
  methods: {
    formatPrice,
  },
})
